import { Cliente } from './../geral/Cliente';
import { OrdemServicoLocacao } from './../residuo/OrdemServicoLocacao';
import { OrdemServico, Equipamento } from '@/core/models/residuo';

export class DetalharMedicaoModel {
    medicao: MedicaoModel = new MedicaoModel();
    itens: ItemModel[] = [];
}

export class MedicaoModel{
    numero: number = 0;
    ordemServicos: OrdemServico[] = [];
    cliente: Cliente = new Cliente();
    observacao: string = '';
    acrescimoLocacao: number = 0;
    descontoLocacao: number = 0;
    totalLocacao: number = 0;
    totalImposto: number = 0;
    total: number = 0;
    totalGeral: number = 0;
}

export class LocacaoModel{
    id: number = 0;
    sequencia: number = 0;
    ordemServico: OrdemServico = new OrdemServico();
    equipamento: Equipamento = new Equipamento();
    equipamentoTroca: Equipamento = new Equipamento();
    parent: OrdemServicoLocacao = new OrdemServicoLocacao();
    dataInicio: string = '';
    dataFim: string = '';
    idaVolta: boolean = true;
    valorUnitario: number = 0;
}

export class ItemModel{
    origem: string = '';
    totalResiduo: number = 0;
    totalServico: number = 0;
    total: number = 0;
    totalGeral: number = 0;
    residuos: ResiduoModel[] = [];
    servicos: ServicoModel[] = [];
    locacoes: LocacaoModel[] = [];
}

export class ResiduoModel{
    numeroOs: number = 0;
    dataOs: string = '';
    nomeResiduo: string = '';
    dataColeta: string = '';
    manifesto1: string = '';
    quantidadeResiduo: number = 0;
    unidadeMedida: string = '';
    valorUnitario: number = 0;
    total: number = 0;
}

export class ServicoModel{
    numeroOs: number = 0;
    dataOs: string = '';
    nomeServico: string = '';
    quantidadeServico: number = 0;
    valorUnitario: number = 0;
    total: number = 0;
}