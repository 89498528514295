


























































































































































































































































































import { Empresa } from '@/core/models/geral';
import { DetalharMedicaoModel } from '@/core/models/model/DetalharMedicaoModel';
import { PageBase } from '@/core/models/shared';
import { MedicaoService } from '@/core/services/residuo';
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class DetalharMedicao extends PageBase {

empresa: Empresa = new Empresa();
item: DetalharMedicaoModel = new DetalharMedicaoModel();
residuos: any[] = [];
servicos: any[] = [];
sessionApp: any = {};
nomeLogo: string = '1.png';
parametro: any;
overlay: boolean = false;

  mounted(){

    const id: number = Number(this.$route.params.id);
    const service = new MedicaoService();
    this.overlay = true;

    service.Detalhar(id).then(
      res=>{
        this.item = res.data;
      },
      err => {
        if (err.response.status == 400) {
          this.$swal("Aviso", err.response.data, "warning");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    ).finally(() =>{
      this.overlay = false;
    })

  }

  Imprimir(){
    window.print();
  }

  Fechar(){
    window.close();
  }

}

